@import "./variable.scss";
@import "./global_fonts.scss";

.more_cars {
  h2 {
    color: map-get($color, primary-1);
    text-align: right;
    font-family: "Avenir-Black";

    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  p {
    text-align: right;
    width: 75%;
    margin-left: auto;

    @media screen and (max-width: 991px) {
      text-align: justify;
      margin-left: unset;
      width: 100%;
    }
  }

  .custom_btn {
    float: right;

    @media screen and (max-width: 991px) {
      float: left;
      margin-bottom: 35px;
    }
  }

  hr {
    @media screen and (max-width: 991px) {
      width: 30%;
      margin-left: unset;
      margin-right: auto;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
